import { render, staticRenderFns } from "./Vrplan.vue?vue&type=template&id=381a9928&scoped=true&"
import script from "./Vrplan.vue?vue&type=script&lang=js&"
export * from "./Vrplan.vue?vue&type=script&lang=js&"
import style0 from "./Vrplan.vue?vue&type=style&index=0&id=381a9928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381a9928",
  null
  
)

export default component.exports